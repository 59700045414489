export const container_ = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  padding: "20px",
  backgroundColor: "#FBFBFB",
  position: "relative",
};

export const flex_ = {
  borderRadius: "5px",
  width: "100%",
  maxWidth: "600px",
  flexDirection: "column",
  padding: "20px",
  gap: "10px",
  backgroundColor: "#fff",
};

export const editFlex_ = {
  borderRadius: "5px",
  width: "100%",
  flexDirection: "column",
  padding: "20px",
  gap: "10px",
  backgroundColor: "#fff",
};

export const input_ = {
  border: "1px solid #FAFFF3",
  outline: "none",
  backgroundColor: "#EDF2F7",
  padding: "15px 10px",
  marginBottom: "10px",

  "&:focus": {
    border: "1px solid #ca951e",
    backgroundColor: "#fff",
  },
};

export const brandText_ = {
  color: "primary",
  fontSize: "30px",
  textAlign: "center",
  fontWeight: "600",
};
