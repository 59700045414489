import { Textarea, Button } from "theme-ui";
import { useForm } from "react-hook-form";
import useAbout from "../../../hooks/aboutus/useAbout";

export const AboutInput = ({ value, payload }) => {
  const { register, handleSubmit } = useForm();

  const { updateAboutUs } = useAbout();

  return (
    <form onSubmit={handleSubmit(updateAboutUs)}>
      <Textarea
        sx={{
          border: "1px solid #ccc",
          backgroundColor: "#fff",
          paddingY: "12px",
          marginBottom: "10px",
          outline: "none",

          "&:focus": {
            border: "1px solid #ca951e",
          },
        }}
        {...register(payload, {
          required: true,
        })}
        className="duration-200"
        placeholder="Say something..."
        defaultValue={value}
        rows={"5"}
      />
      <Button type="submit">Update</Button>
    </form>
  );
};
