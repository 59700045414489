import React from "react";
import { ScaleLoader } from "react-spinners";

const Loader = () => {
  return (
    <div
      className="w-full flex items-center justify-center"
      style={{ height: "200px", flex: "4", border: "2px solid #fafafa" }}
    >
      <ScaleLoader size={150} color={"#0e542e"} />
    </div>
  );
};

export default Loader;
