import React from "react";
import PageLayout from "../../components/layout/PageLayout";
import useFaq from "../../hooks/faq/useFaq";
import FaqItem from "./FaqItem";
import { Box } from "theme-ui";
import { AddNewBtn } from "./components";

const Faq = () => {
  const { faqData, isLoading, handleAddFaq } = useFaq();

  const pageProps = { isLoading, title: "F.A.Q" };

  // console.log(faqData);

  return (
    <PageLayout {...pageProps}>
      <Box padding={"20px"}>
        {faqData &&
          faqData?.map((item, index) => (
            <FaqItem key={index} index={index} item={item} />
          ))}
        <AddNewBtn onClick={handleAddFaq} />
      </Box>
    </PageLayout>
  );
};

export default Faq;
