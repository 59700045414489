import ApiClient from "./api";

const client = ApiClient();

export const api = {
  getTeamMembers: () =>
    client
      .get(`/api/v1/getTeamMember?sort=websitePosition`)
      .then(({ data }) => data),

  createTeamMember: (payload) =>
    client.post(`/api/v1/createTeamMember`, payload).then(({ data }) => data),

  updateTeamMember: ({ _id, payload }) =>
    client
      .patch(`/api/v1/editTeamMember/${_id}`, payload)
      .then(({ data }) => data),

  deleteTeamMember: (_id) =>
    client.delete(`/api/v1/deleteTeamMember/${_id}`).then(({ data }) => data),
};
