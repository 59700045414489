import React from "react";
import { Box, Input, Button } from "theme-ui";
import { input_ } from "../Signin.styles";
import { useForm } from "react-hook-form";
import useProducts from "../../hooks/products/useProducts";

const AddProduct = () => {
  const { register, handleSubmit } = useForm();

  const { addNewProduct, setPicture } = useProducts();

  return (
    <Box>
      <form onSubmit={handleSubmit(addNewProduct)}>
        <Input
          type="text"
          {...register("productName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="Product Name *"
        />
        <Input
          type="file"
          onChange={(e) => setPicture(e.target.files[0])}
          sx={input_}
          className="duration-200 focus:bg-white"
        />
        <Button
          sx={{
            backgroundColor: "primary",
            padding: "15px 0",
            width: "100%",
          }}
          type="submit"
        >
          Add Product
        </Button>
      </form>
    </Box>
  );
};

export default AddProduct;
