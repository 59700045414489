import React, { useState, useEffect, useRef } from "react";
// import { MoreVertOutlined } from "@material-ui/icons";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const VerticalMenu = ({ children, isBackground }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef();

  const handleOutsideClick = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  const handleToggle = () => {};
  return (
    <div className="relative  py-1 px-2 rounded" ref={menuRef}>
      {/* <MoreVertOutlined
        className={`cursor-pointer ${isBackground ? "text-slate-500" : ""}`}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      /> */}
      <button
        className="bg-transparent"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        <FontAwesomeIcon
          className={`cursor-pointer ${isBackground ? "text-slate-500" : ""}`}
          icon={faEllipsisV}
        />
      </button>
      {isMenuOpen && (
        <div
          style={{ right: "0", top: "23px" }}
          className={`absolute z-10 w-48 mt-1 h-auto ease-in-out transition-all origin-top-right bg-white rounded-md shadow-lg dark:bg-gray-800 ring-1 ring-black ring-opacity-5 `}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default VerticalMenu;
