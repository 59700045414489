import React from "react";
import { Input } from "./components";
import { Box } from "theme-ui";
import useContact from "../../../hooks/contactus/useContact";

const Instagram = () => {
  const { data } = useContact();

  const { instagram } = data;

  return (
    <Box sx={{ padding: "5px" }}>
      <Input payload={"instagram"} value={instagram} />
    </Box>
  );
};

export default Instagram;
