import React from "react";
import PageLayout from "../../components/layout/PageLayout";
import SubscribersList from "./SubscribersList";
import { Button } from "theme-ui";
import useNewsletter from "../../hooks/newsletter/useNewsletter";
import CenteredModal from "../../components/modal/Modal";
import News from "./News";

const Newsletter = () => {
  const { pageProps, modalProps, handleModal } = useNewsletter();

  return (
    <PageLayout {...pageProps}>
      <Button backgroundColor="secondary" onClick={handleModal}>
        Send Newsletter
      </Button>
      <SubscribersList />
      <CenteredModal {...modalProps}>
        <News />
      </CenteredModal>
    </PageLayout>
  );
};

export default Newsletter;
