export const button = {
  backgroundColor: "transparent",
  width: "10px",
};

export const container = {
  justifyContent: "space-between",
  alignItems: "start",
  padding: "10px",
  overflowX: "scroll",
};

export const products = { marginTop: "10px", padding: "10px" };
