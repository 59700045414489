import React from "react";
import { Box, Input, Button } from "theme-ui";
import { input_ } from "../Signin.styles";
import { useForm } from "react-hook-form";
import useProducts from "../../hooks/products/useProducts";

const EditProduct = () => {
  const { register, handleSubmit } = useForm();

  const { updateProduct, setPicture, picture } = useProducts();

  const current_product = JSON.parse(sessionStorage.getItem("current_product"));

  return (
    <Box>
      <form onSubmit={handleSubmit(updateProduct)}>
        <Input
          type="text"
          {...register("productName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          defaultValue={current_product?.productName}
          placeholder="Product Name *"
        />
        <Input
          type="file"
          onChange={(e) => setPicture(e.target.files[0])}
          sx={{ display: "none" }}
        />
        <Box sx={input_}>
          <label className="cursor-pointer" htmlFor="file_input">
            {picture ? picture?.name : current_product?.picture}
          </label>
        </Box>
        <Button
          sx={{
            backgroundColor: "primary",
            padding: "15px 0",
            width: "100%",
          }}
          type="submit"
        >
          Edit Product
        </Button>
      </form>
    </Box>
  );
};

export default EditProduct;
