import React from "react";
import { Box, Text, Input, Flex, Button, Image } from "theme-ui";
import { useForm } from "react-hook-form";
import { container_, flex_, input_, brandText_ } from "../Signin.styles";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import toast from "react-hot-toast";
import { api } from "../../api/auth";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";

const Signin = () => {
  const { register, handleSubmit } = useForm();

  const navigate = useNavigate();
  const { mutateAsync } = useMutation(api.forgotPassword);

  const handleForgotPassword = async (values) => {
    // console.log(values);
    toast.loading("Processing...");

    try {
      const data = await mutateAsync(values);
      toast.remove();
      console.log(data);
      toast.success("Kindly check your e-mail...");
      setTimeout(() => {
        navigate("/reset-password");
      }, 1000);
    } catch ({ response }) {
      // console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <React.Fragment>
      <Box sx={container_}>
        <Image
          sx={{ position: "absolute", top: "20px", left: "20px" }}
          src={logo}
          width={100}
          height={50}
        />
        <Flex sx={flex_}>
          <Text sx={brandText_}>Forgot Password?</Text>
          <form onSubmit={handleSubmit(handleForgotPassword)}>
            <Input
              type="email"
              {...register("email", {
                required: true,
              })}
              sx={input_}
              className="duration-200 focus:bg-white"
              placeholder="E-mail *"
            />
            <Button
              sx={{
                backgroundColor: "secondary",
                padding: "15px 0",
                width: "100%",
              }}
              type="submit"
            >
              Proceed
            </Button>
          </form>
          <div className="flex items-center justify-center text-[#3182CE]">
            <Link to={"/"} className="hover:underline">
              Back to Login?
            </Link>
          </div>
        </Flex>
      </Box>
    </React.Fragment>
  );
};

export default Signin;
