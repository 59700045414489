import React from "react";
import PageLayout from "../../components/layout/PageLayout";
import TeamList from "./TeamList";
import useTeam from "../../hooks/team/useTeam";
import { Button } from "theme-ui";
import CenteredModal from "../../components/modal/Modal";
import AddMember from "./AddMember";

const Team = () => {
  const { isLoading, addModal, handleAddModal } = useTeam();

  const pageProps = { title: "Team", isLoading };

  return (
    <PageLayout {...pageProps}>
      <Button onClick={handleAddModal}>Add New Member</Button>
      <TeamList />
      <CenteredModal title={"."} open={addModal} setOpen={handleAddModal}>
        <AddMember />
      </CenteredModal>
    </PageLayout>
  );
};

export default Team;
