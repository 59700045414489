import React from "react";
import { Flex, Input, Button, Text } from "theme-ui";
import { useForm } from "react-hook-form";
import { input_, flex_ } from "../Signin.styles";
import useAddUsers from "../../hooks/users/useAddUser";

const AddStaff = () => {
  const { register, handleSubmit } = useForm();

  const { roles, handlePermissions, selectedPermissions, handleSignUp } =
    useAddUsers();

  //   console.log(roles);

  //   console.log(selectedPermissions);

  return (
    <Flex sx={flex_}>
      <form onSubmit={handleSubmit(handleSignUp)}>
        <Input
          type="text"
          {...register("fName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="First Name *"
        />
        <Input
          type="text"
          {...register("lName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="Last Name *"
        />
        <Input
          type="email"
          {...register("email", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="E-mail *"
        />
        <Flex sx={{ width: "100%" }}>
          <Text>Add Permissions</Text>
        </Flex>
        <Flex
          sx={{
            border: "1px solid #ccc",
            marginTop: "5px",
            marginBottom: "10px",
            gap: "20px",
            flexWrap: "wrap",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          {roles?.map((role) => (
            <Flex sx={{ gap: "5px" }} key={role?._id}>
              <input
                type="checkbox"
                checked={selectedPermissions.includes(role?.permission)}
                onChange={() => handlePermissions(role)}
              />
              {role?.permission}
            </Flex>
          ))}
        </Flex>
        <Button
          sx={{
            backgroundColor: "primary",
            padding: "15px 0",
            width: "100%",
          }}
          type="submit"
        >
          Create Account
        </Button>
      </form>
    </Flex>
  );
};

export default AddStaff;
