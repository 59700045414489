import React from "react";
import { AboutInput } from "./components";
import { Box } from "theme-ui";
import useAbout from "../../../hooks/aboutus/useAbout";

const WhoWeAre = () => {
  const { data } = useAbout();

  const { whoWeAre } = data;

  return (
    <Box sx={{ padding: "5px" }}>
      <AboutInput payload={"whoWeAre"} value={whoWeAre} />
    </Box>
  );
};

export default WhoWeAre;
