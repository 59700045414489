export const toastOptions = {
  style: {
    color: "#023047",
  },
  success: {
    iconTheme: {
      primary: "#0e542e",
    },
    style: {
      color: "#023047",
    },
  },
  error: {
    iconTheme: {
      primary: "#ca951e",
    },
    style: {
      color: "#023047",
    },
  },
};
