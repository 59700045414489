import { Box, Button, Input, Text } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

export const Container = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#f8f8f8",
        borderRadius: "7px",
        marginY: "20px",
        padding: "10px",
      }}
    >
      {children}
    </Box>
  );
};

export const Header = ({ id }) => {
  return <Text>{id ? "Edit Question" : "Add Question"}</Text>;
};

export const SubHeader = ({ id }) => {
  return <Text>{id ? "Edit Answer" : "Add Answer"}</Text>;
};

export const QuestionInput = ({ value, onChange }) => {
  return (
    <Input
      sx={{
        border: "1px solid transparent",
        backgroundColor: "#fff",
        paddingY: "12px",
        marginY: "10px",
        outline: "none",

        "&:focus": {
          border: "1px solid #ca951e",
        },
      }}
      className="duration-200"
      placeholder="Say something..."
      defaultValue={value}
      onChange={onChange}
    />
  );
};

export const AnswerInput = ({ value, onChange }) => {
  return (
    <Input
      sx={{
        border: "1px solid transparent",
        backgroundColor: "#fff",
        paddingY: "12px",
        marginY: "5px",
        outline: "none",

        "&:focus": {
          border: "1px solid #ca951e",
        },
      }}
      className="duration-200"
      placeholder="Say something..."
      defaultValue={value}
      onChange={onChange}
    />
  );
};

export const DeleteBtn = ({ onClick }) => {
  return (
    <Button sx={{ backgroundColor: "transparent" }} onClick={onClick}>
      <FontAwesomeIcon className="text-secondary" icon={faTrash} />
    </Button>
  );
};

export const HandleBtn = ({ onClick, id, index }) => {
  const current_faq_index = JSON.parse(
    sessionStorage.getItem("current_faq_index")
  );

  return (
    <Button
      disabled={index !== current_faq_index}
      className="duration-200"
      onClick={onClick}
      sx={{
        border: "2px solid #ca951e",
        backgroundColor: "transparent",
        color: "#ca951e",
        cursor: index !== current_faq_index ? "not-allowed" : "pointer",

        "&:hover": {
          backgroundColor: "#ca951e",
          color: "#fff",
        },
      }}
    >
      {id ? "Update F.A.Q" : "Add F.A.Q"}
    </Button>
  );
};

export const AddNewBtn = ({ onClick }) => {
  return (
    <Button paddingY={"13px"} paddingX={"25px"} onClick={onClick}>
      Add New F.A.Q
    </Button>
  );
};

export const bottomFlex = {
  alignItems: "flex-end",
  justifyContent: "space-between",
  marginTop: "10px",
};
