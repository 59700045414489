import ApiClient from "./api";

const client = ApiClient();

export const api = {
  getContactUs: () =>
    client.get(`/api/v1/getContactUs`).then(({ data }) => data),

  editContactUs: (payload) =>
    client.patch(`/api/v1/editContactUs`, payload).then(({ data }) => data),
};
