import ApiClient from "./api";

const client = ApiClient();

export const api = {
  getReviews: () =>
    client.get(`/api/v1/getReview?sort=-dateCreated`).then(({ data }) => data),

  createReview: (payload) =>
    client.post(`/api/v1/createReview`, payload).then(({ data }) => data),

  updateReview: ({ _id, payload }) =>
    client.patch(`/api/v1/editReview/${_id}`, payload).then(({ data }) => data),

  deleteReview: (_id) =>
    client.delete(`/api/v1/deleteReview/${_id}`).then(({ data }) => data),
};
