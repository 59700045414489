import React from "react";
import { Box, Input, Button, Textarea } from "theme-ui";
import { input_ } from "../Signin.styles";
import { useForm } from "react-hook-form";
import useReview from "../../hooks/review/useReview";

const CreateReview = () => {
  const { register, handleSubmit } = useForm();

  const { addNewReview, setPicture } = useReview();

  return (
    <Box>
      <form onSubmit={handleSubmit(addNewReview)}>
        <Input
          type="text"
          {...register("fName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="First Name *"
        />
        <Input
          type="text"
          {...register("lName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="Last Name *"
        />
        <Textarea
          sx={input_}
          {...register("review", {
            required: true,
          })}
          className="duration-200"
          placeholder="Review *"
          rows={"5"}
        />
        <Input
          type="file"
          onChange={(e) => setPicture(e.target.files[0])}
          sx={input_}
          className="duration-200 focus:bg-white"
        />
        <Button
          sx={{
            backgroundColor: "primary",
            padding: "15px 0",
            width: "100%",
          }}
          type="submit"
        >
          Add Review
        </Button>
      </form>
    </Box>
  );
};

export default CreateReview;
