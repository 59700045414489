/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import PageLayout from "../../components/layout/PageLayout";
import { useQuery, useMutation } from "react-query";
import { api } from "../../api/gallery";
import { Box, Input, Button, Text, Flex } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPlus, faEye } from "@fortawesome/free-solid-svg-icons";
import FileInput from "./components/FileInput";
import toast from "react-hot-toast";
import ImageModal from "./components/ImageModal";
import { v4 as uuidv4 } from "uuid";

const Gallery = () => {
  const { mutateAsync: EditGallery } = useMutation(api.editGallery);
  const { mutateAsync: CreateGallery } = useMutation(api.createGallery);
  const { mutateAsync: DeleteGallery } = useMutation(api.deleteGallery);

  const {
    data: gallery_data,
    isLoading,
    refetch,
  } = useQuery(
    ["Gallery Data"],
    () => {
      return api.getGallery();
    },
    {
      cacheTime: 300,
      staleTime: 300,
      select: (data) => data?.message,
    }
  );

  const current_image_index = JSON.parse(
    sessionStorage.getItem("current_image_index")
  );
  const current_image_tag = sessionStorage.getItem("current_image_tag");

  const [galleryData, setGalleryData] = useState(gallery_data || []);
  const [newCaption, setNewCaption] = useState("");
  const [newPicture, setNewPicture] = useState("");
  const [modal, showModal] = useState(false);

  const handleNewCaption = (index) => {
    return (e) => {
      // console.log(index);
      sessionStorage.setItem("current_image_index", index);
      setNewCaption(e.target.value);
    };
  };

  // console.log(newCaption);

  // console.log(galleryData);

  const handleNewPicture = (e) => {
    const index = e.target.getAttribute("data-index");
    console.log(index, "inner");

    setNewPicture(e.target.files[0]);

    sessionStorage.setItem("current_image_index", index);
    sessionStorage.setItem("current_image_tag", e.target.files[0].name);
  };

  const handleAddImage = () => {
    const newImage = {
      caption: "",
      picture: "",
      // dateCreated: new Date().toISOString(),
      temp_id: uuidv4(),
    };
    setGalleryData([...galleryData, newImage]);
    setNewCaption("");
    setNewPicture("");
    sessionStorage.removeItem("current_image_index");
    sessionStorage.removeItem("current_image_tag");
  };

  const removeImage = async (currentImage) => {
    if (
      confirm("Kindly confirm you wish to remove this image before proceeding?")
    ) {
      // console.log(currentImage);
      if (currentImage && !currentImage._id) {
        return setGalleryData((prev) =>
          prev.filter((data) => data.temp_id !== currentImage.temp_id)
        );
      }
      toast.loading("Deleting Image...");

      try {
        const data = await DeleteGallery(currentImage?._id);
        // console.log(data);
        toast.remove();
        toast.success(data?.message);
        sessionStorage.removeItem("current_image_index");
        sessionStorage.removeItem("current_image_tag");
        setTimeout(() => {
          refetch();
          location.reload();
        }, 1500);
      } catch ({ response }) {
        console.log(response);
        toast.remove();
        toast.error(response?.data?.message);
      }
    }
  };

  const updateImage = async (image) => {
    toast.loading("Updating...");

    const payload = new FormData();
    newCaption && payload.append("caption", newCaption);
    newPicture && payload.append("picture", newPicture);

    try {
      const data = await EditGallery({ id: image._id, payload });
      // console.log(data);
      toast.remove();
      toast.success(data?.message);
      sessionStorage.removeItem("current_image_index");
      sessionStorage.removeItem("current_image_tag");
      setTimeout(() => {
        refetch();
        location.reload();
      }, 1500);
    } catch ({ response }) {
      console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const addImage = async () => {
    toast.loading("Creating Image...");

    const payload = new FormData();
    payload.append("caption", newCaption);
    payload.append("picture", newPicture);

    try {
      const data = await CreateGallery(payload);
      // console.log(data);
      toast.remove();
      toast.success(data?.message);
      sessionStorage.removeItem("current_image_index");
      sessionStorage.removeItem("current_image_tag");
      setTimeout(() => {
        refetch();
        location.reload();
      }, 1500);
    } catch ({ response }) {
      console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  // console.log(gallery_data);

  // console.log(galleryData, "g");

  useEffect(() => {
    setGalleryData(gallery_data);
  }, [gallery_data]);

  const pageProps = { isLoading, title: "Gallery" };

  const handlePictureTag = (index, picture) => {
    if (current_image_index === index) {
      return current_image_tag ? (
        <p>{current_image_tag}</p>
      ) : (
        <p>
          <FontAwesomeIcon icon={faPlus} /> Select Image
        </p>
      );
    }

    return picture ? (
      picture
    ) : (
      <p>
        <FontAwesomeIcon icon={faPlus} /> Select Image
      </p>
    );
  };

  const handleImage = (image) => {
    if (image?._id) return updateImage(image);

    return addImage();
  };

  const handleModal = () => {
    showModal(!modal);
  };

  const toggleModal = (data) => {
    toast.loading("Processing...");
    sessionStorage.setItem("current_url", data?.url);
    sessionStorage.setItem("current_picture", data?.picture);

    setTimeout(() => {
      toast.remove();
      handleModal();
    }, 1000);
  };

  return (
    <PageLayout {...pageProps}>
      {modal && <ImageModal handleModal={handleModal} />}
      <Box paddingX={"20px"}>
        {galleryData &&
          galleryData?.map((data, index) => (
            <Box
              sx={{
                backgroundColor: "#f8f8f8",
                borderRadius: "7px",
                marginY: "20px",
                padding: "10px",
              }}
              key={index}
            >
              <Flex
                sx={{ alignItems: "center", justifyContent: "space-between" }}
              >
                <Text>{data._id ? "Edit Image" : "Upload an Image"}</Text>
                {data?._id && (
                  <Button
                    sx={{ backgroundColor: "transparent" }}
                    onClick={() => toggleModal(data)}
                  >
                    <FontAwesomeIcon className="text-slate-500" icon={faEye} />
                  </Button>
                )}
              </Flex>
              <Flex
                sx={{
                  border: "1px solid transparent",
                  paddingY: "35px",
                  backgroundColor: "#fff",
                  marginTop: "5px",
                  marginBottom: "20px",
                  outline: "none",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label
                  htmlFor={`file_input_${index}`}
                  className="cursor-pointer"
                >
                  {handlePictureTag(index, data?.picture)}
                </label>
              </Flex>
              <Input
                id={`file_input_${index}`}
                sx={{
                  border: "1px solid transparent",
                  paddingY: "40px",
                  backgroundColor: "#fff",
                  marginTop: "5px",
                  marginBottom: "20px",
                  outline: "none",
                  display: "none",

                  "&:focus": {
                    border: "1px solid #ca951e",
                  },
                }}
                data-index={index}
                className="duration-200"
                type="file"
                onChange={handleNewPicture}
              />
              <Text>
                {data._id ? "Edit Description" : "Write a short description"}
              </Text>
              <Input
                sx={{
                  border: "1px solid transparent",
                  backgroundColor: "#fff",
                  paddingY: "12px",
                  marginY: "5px",
                  outline: "none",

                  "&:focus": {
                    border: "1px solid #ca951e",
                  },
                }}
                className="duration-200"
                placeholder="Say something..."
                defaultValue={data?.caption}
                onChange={handleNewCaption(index)}
              />
              <Flex
                sx={{
                  alignItems: "flex-end",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <Button
                  sx={{ backgroundColor: "transparent" }}
                  onClick={() => removeImage(data)}
                >
                  <FontAwesomeIcon className="text-secondary" icon={faTrash} />
                </Button>
                <Button
                  disabled={index !== current_image_index}
                  className="duration-200"
                  onClick={() => handleImage(data)}
                  sx={{
                    border: "2px solid #ca951e",
                    backgroundColor: "transparent",
                    color: "#ca951e",
                    cursor:
                      index !== current_image_index ? "not-allowed" : "pointer",

                    "&:hover": {
                      backgroundColor: "#ca951e",
                      color: "#fff",
                    },
                  }}
                >
                  {data._id ? "Update Image" : "Add Image"}
                </Button>
              </Flex>
            </Box>
          ))}
        <Button paddingY={"13px"} paddingX={"25px"} onClick={handleAddImage}>
          Add New Image
        </Button>
      </Box>
    </PageLayout>
  );
};

export default Gallery;
