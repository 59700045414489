import React from "react";
import { Box, Image } from "theme-ui";
import { routes } from "../../routes";
import { Link, useLocation } from "react-router-dom";
import {
  stickyStyle_,
  linkStyle_,
  activePath_,
  dormantPath_,
  imageStyle,
} from "./style";
import Logo from "../logo/Logo";
import logo from "../../assets/logo.png";

const Stickybar = ({ setIsOpen }) => {
  const location = useLocation();

  return (
    <Box sx={stickyStyle_}>
      {/* <Logo /> */}
      <Image src={logo} width={100} height={50} sx={imageStyle} />
      {routes.map((route) => (
        <Link
          key={route.id}
          style={linkStyle_}
          className={
            location.pathname === route.path ? activePath_ : dormantPath_
          }
          to={route.path}
          onClick={() => setIsOpen(false)}
        >
          {route.icon} <p>{route.title}</p>
        </Link>
      ))}
    </Box>
  );
};

export default Stickybar;
