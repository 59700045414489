import React from "react";
import { Box, Input, Button, Textarea } from "theme-ui";
import { input_ } from "../Signin.styles";
import { useForm } from "react-hook-form";
import useTeam from "../../hooks/team/useTeam";

const EditMember = () => {
  const { register, handleSubmit } = useForm();

  const { updateTeamMember, setPicture, picture } = useTeam();

  const current_team_member = JSON.parse(
    sessionStorage.getItem("current_team_member")
  );

  return (
    <Box>
      <form onSubmit={handleSubmit(updateTeamMember)}>
        <Input
          type="text"
          {...register("fName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          defaultValue={current_team_member?.fName}
          placeholder="First Name *"
        />
        <Input
          type="text"
          {...register("lName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          defaultValue={current_team_member?.lName}
          placeholder="Last Name *"
        />
        <Input
          type="email"
          {...register("email", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          defaultValue={current_team_member?.email}
          placeholder="E-mail *"
        />
        <Textarea
          sx={input_}
          {...register("about", {
            required: true,
          })}
          className="duration-200"
          defaultValue={current_team_member?.about}
          placeholder="About *"
          rows={"5"}
        />
        <Input
          type="text"
          {...register("role", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          defaultValue={current_team_member?.role}
          placeholder="Role *"
        />
        <Input
          type="number"
          {...register("websitePosition", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          defaultValue={current_team_member?.websitePosition}
          placeholder="Website Position *"
        />
        <Box sx={input_}>
          <label className="cursor-pointer" htmlFor="file_input">
            {picture ? picture?.name : current_team_member?.picture}
          </label>
        </Box>
        <Input
          type="file"
          id="file_input"
          onChange={(e) => setPicture(e.target.files[0])}
          sx={{ display: "none" }}
          //   className="duration-200 focus:bg-white"
        />
        <Button
          sx={{
            backgroundColor: "primary",
            padding: "15px 0",
            width: "100%",
          }}
          type="submit"
        >
          Edit Member
        </Button>
      </form>
    </Box>
  );
};

export default EditMember;
