import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImages,
  faUserGroup,
  faCircleQuestion,
  faCircleInfo,
  faLocationDot,
  faEnvelopesBulk,
  faUsersGear,
  faMessage,
  faHandshake,
  faTractor,
} from "@fortawesome/free-solid-svg-icons";

export const routes = [
  {
    id: 1,
    title: "Gallery",
    path: "/dashboard/gallery",
    icon: <FontAwesomeIcon icon={faImages} />,
  },
  {
    id: 2,
    title: "About Us",
    path: "/dashboard/about-us",
    icon: <FontAwesomeIcon icon={faCircleInfo} />,
  },
  {
    id: 3,
    title: "F.A.Q",
    path: "/dashboard/faq",
    icon: <FontAwesomeIcon icon={faCircleQuestion} />,
  },
  {
    id: 4,
    title: "Users",
    path: "/dashboard/users",
    icon: <FontAwesomeIcon icon={faUserGroup} />,
  },
  {
    id: 5,
    title: "Contact Us",
    path: "/dashboard/contact-us",
    icon: <FontAwesomeIcon icon={faLocationDot} />,
  },
  {
    id: 6,
    title: "Newsletter",
    path: "/dashboard/newsletter",
    icon: <FontAwesomeIcon icon={faEnvelopesBulk} />,
  },
  {
    id: 7,
    title: "Team",
    path: "/dashboard/team",
    icon: <FontAwesomeIcon icon={faUsersGear} />,
  },
  {
    id: 8,
    title: "Review",
    path: "/dashboard/review",
    icon: <FontAwesomeIcon icon={faMessage} />,
  },
  {
    id: 9,
    title: "Partners",
    path: "/dashboard/partners",
    icon: <FontAwesomeIcon icon={faHandshake} />,
  },
  {
    id: 10,
    title: "Products",
    path: "/dashboard/products",
    icon: <FontAwesomeIcon icon={faTractor} />,
  },
];
