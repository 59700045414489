import React from "react";
import { Box } from "theme-ui";
import usePartners from "../../hooks/partners/usePartners";
import Faq from "react-faq-component";
import { partners } from "./style";

const PartnersList = () => {
  const { faqProps } = usePartners();

  return (
    <Box sx={partners}>
      <Faq {...faqProps} />
    </Box>
  );
};

export default PartnersList;
