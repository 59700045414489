import React from "react";
import { Flex, Box, Text } from "theme-ui";
import Loader from "../loader/Loader";
import useWindowDimensions from "../../hooks/useWindowSize";

const PageLayout = ({ children, title, isLoading }) => {
  const { width } = useWindowDimensions();
  const isDesktop = width > 768;

  return (
    <Flex
      sx={{
        margin: "10px",
        backgroundColor: "#fff",
        flexDirection: isDesktop ? "row" : "column",
      }}
    >
      <Text
        sx={{
          flex: "1",
          paddingY: "20px",
          paddingX: "8px",
          fontSize: "20px",
          border: "2px solid #fafafa",
          fontWeight: "600",
        }}
      >
        {title || `Title`}
      </Text>

      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ flex: "4", padding: "8px", border: "2px solid #fafafa" }}>
          {children}
        </Box>
      )}
    </Flex>
  );
};

export default PageLayout;
