/* eslint-disable no-restricted-globals */
import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { api } from "../../api/team";
import toast from "react-hot-toast";

const useTeam = () => {
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [picture, setPicture] = useState(null);

  const { mutateAsync: CreateMember } = useMutation(api.createTeamMember);
  const { mutateAsync: DeleteMember } = useMutation(api.deleteTeamMember);
  const { mutateAsync: UpdateMember } = useMutation(api.updateTeamMember);

  const { data, isLoading } = useQuery(
    ["Team"],
    () => {
      return api.getTeamMembers();
    },
    {
      cacheTime: 300,
      staleTime: 300,
      select: (data) => data?.message,
    }
  );

  const handleModal = () => {
    setModal(!modal);
  };

  const handleAddModal = () => {
    setAddModal(!addModal);
  };

  const handleEditModal = () => {
    setEditModal(!editModal);
  };

  const addNewMember = async (values) => {
    if (!picture) return toast.error("Please upload a picture");

    toast.loading("Adding new member...");

    try {
      const payload = new FormData();
      payload.append("fName", values?.fName);
      payload.append("lName", values?.lName);
      payload.append("email", values?.email);
      payload.append("about", values?.about);
      payload.append("role", values?.role);
      picture && payload.append("picture", picture);
      payload.append("websitePosition", values?.websitePosition);

      const data = await CreateMember(payload);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const removeTeamMember = async () => {
    const current_team_member = JSON.parse(
      sessionStorage.getItem("current_team_member")
    );
    const { fName, lName, _id } = current_team_member;

    if (confirm(`Are you sure you want to remove ${fName} ${lName}`)) {
      toast.loading("Removing member...");

      try {
        const data = await DeleteMember(_id);
        toast.remove();
        toast.success(data?.message);
        setTimeout(() => {
          location.reload();
        }, 1000);
      } catch ({ response }) {
        toast.remove();
        toast.error(response?.data?.message);
      }
    }
  };

  const updateTeamMember = async (values) => {
    const current_team_member = JSON.parse(
      sessionStorage.getItem("current_team_member")
    );
    const { _id } = current_team_member;

    toast.loading("Updating team member...");

    try {
      const payload = new FormData();
      payload.append("fName", values?.fName);
      payload.append("lName", values?.lName);
      payload.append("email", values?.email);
      payload.append("about", values?.about);
      payload.append("role", values?.role);
      picture && payload.append("picture", picture);
      payload.append("websitePosition", values?.websitePosition);

      const data = await UpdateMember({ _id, payload });
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return {
    data,
    isLoading,
    modal,
    handleModal,
    addModal,
    handleAddModal,
    editModal,
    handleEditModal,
    addNewMember,
    removeTeamMember,
    updateTeamMember,
    picture,
    setPicture,
  };
};

export default useTeam;
