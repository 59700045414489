export const headerStyle_ = {
  padding: "15px 10px",
  alignItems: "center",
  gap: "5px",
  backgroundColor: "#fff",

  "@media (max-width: 768px)": {
    borderBottom: "1px solid #ccc",
  },
};

export const buttonStyle_ = {
  marginLeft: "10px",
  backgroundColor: "#ca951e",
  color: "#fff",
  border: "1px solid #ca951e",
  padding: "3px 15px",
};

export const boxStyle_ = {
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  gap: "5px",
};
