import React, { useState, useEffect } from "react";
import { Flex, Box } from "theme-ui";
import Sidebar from "../../components/sidebar/Sidebar";
import Stickybar from "../../components/stickybar/Stickybar";
import Header from "../../components/header/Header";
import { Routes, Route, Navigate } from "react-router-dom";
import Gallery from "../gallery/Gallery";
import Users from "../users/Users";
import Faq from "../faq/Faq";
import ContactUs from "../contactus/ContactUs";
import AboutUs from "../aboutus/AboutUs";
import Newsletter from "../newsletter/Newsletter";
import Team from "../team/Team";
import Review from "../review/Review";
import Partners from "../partners/Partners";
import Products from "../products/Products";
import useWindowDimensions from "../../hooks/useWindowSize";

const DashboardRoutes = () => {
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 768) {
      setToggleSidebar(false);
    } else {
      setToggleSidebar(true);
      setIsOpen(false);
    }
  }, [width]);

  return (
    <React.Fragment>
      <Flex sx={{ height: "100vh" }}>
        {toggleSidebar && <Sidebar />}
        <Box
          sx={{ flex: toggleSidebar ? "4" : "1", backgroundColor: "#fafafa" }}
        >
          <Header width={width} isOpen={isOpen} setIsOpen={setIsOpen} />
          {isOpen && <Stickybar setIsOpen={setIsOpen} />}
          <Routes>
            <Route path="/" element={<Navigate to={"gallery"} />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/faq" element={<Faq />} />
            <Route path="/users" element={<Users />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/newsletter" element={<Newsletter />} />
            <Route path="/team" element={<Team />} />
            <Route path="/review" element={<Review />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/products" element={<Products />} />
          </Routes>
        </Box>
      </Flex>
    </React.Fragment>
  );
};

export default DashboardRoutes;
