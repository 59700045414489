export const stickyStyle_ = {
  flex: "0.9",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#fff",
  position: "fixed",
  height: "100vh",
  width: "200px",
  zIndex: "200",
  borderRight: "1px solid #ccc",
};

export const linkStyle_ = {
  width: "90%",
  margin: "10px auto",
  padding: "5px 10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "10px",
};

export const imageStyle = {
  marginBottom: "25px",

  "@media (max-width: 768px)": {
    marginTop: "20px",
  },
};

//styling when the link is active
export const activePath_ = "my-5 text-link_color";

//Styling when the link is dormant
export const dormantPath_ = "my-5 text-black";
