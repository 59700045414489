export const theme = {
  fonts: {
    body: "system-ui, sans-serif",
    heading: '"Avenir Next", sans-serif',
    monospace: "Menlo, monospace",
  },
  colors: {
    primary: "#0e542e",
    sub_secondary: "#ffc950",
    secondary: "#ca951e",
    gray: "#666666",
    paleGreen: "#F0FEDF",
    paleGrey: "#FAFFF3",
  },
};
