import React, { useRef } from "react";
import "./Table.css";

const Table = ({ columns, data }) => {
  const table = useRef();

  return (
    <React.Fragment>
      <table ref={table} className={"Parent_Style"}>
        <thead>
          <tr>
            {columns.map((header) => (
              <th key={header.key}>{header.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((row, index) => (
            <tr key={index}>
              {columns.map((column) => {
                const item = row[column.selector];
                return (
                  <td key={column.key}>
                    {column.render ? column.render({ item, row, index }) : item}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </React.Fragment>
  );
};

export default Table;
