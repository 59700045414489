import axios from "axios";
import toast from "react-hot-toast";

const ApiClient = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const client = axios.create({
    baseURL: baseUrl,
    headers: {
      "Cache-Control": "no-cache",
    },
  });

  client.interceptors.request.use(
    async (config) => {
      const token = sessionStorage.getItem("user_token");

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401 && error.config.url !== "/login") {
        sessionStorage.setItem("isLoggedIn", false);
        toast.remove();
        toast.error("Unauthenticated, kindly login again...");
      }
      return Promise.reject(error);
    }
  );

  return client;
};

export default ApiClient;
