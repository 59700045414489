import ApiClient from "./api";

const client = ApiClient();

export const api = {
  getProducts: () => client.get(`/api/v1/getProduct`).then(({ data }) => data),

  createProduct: (payload) =>
    client.post(`/api/v1/createProduct`, payload).then(({ data }) => data),

  updateProduct: ({ _id, payload }) =>
    client
      .patch(`/api/v1/editProduct/${_id}`, payload)
      .then(({ data }) => data),

  deleteProduct: (_id) =>
    client.delete(`/api/v1/deleteProduct/${_id}`).then(({ data }) => data),
};
