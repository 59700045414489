import React from "react";
import useFaq from "../../hooks/faq/useFaq";
import {
  Container,
  Header,
  SubHeader,
  QuestionInput,
  HandleBtn,
  DeleteBtn,
  AnswerInput,
  bottomFlex,
} from "./components";
import { Flex } from "theme-ui";

const FaqItem = ({ item, index }) => {
  const { handleQuestion, handleFaq, handleAnswer, removeFaq } = useFaq();

  return (
    <Container>
      <Header id={item?._id} />
      <QuestionInput value={item?.question} onChange={handleQuestion(index)} />
      <SubHeader id={item?._id} />
      <AnswerInput value={item?.answer} onChange={handleAnswer(index)} />
      <Flex sx={bottomFlex}>
        <DeleteBtn onClick={removeFaq(item)} />
        <HandleBtn index={index} id={item?._id} onClick={handleFaq(item)} />
      </Flex>
    </Container>
  );
};

export default FaqItem;
