import ApiClient from "./api";

const client = ApiClient();

export const api = {
  getGallery: () =>
    client.get(`/api/v1/getGallery?sort=-dateCreated`).then(({ data }) => data),

  editGallery: ({ id, payload }) =>
    client.patch(`/api/v1/editGallery/${id}`, payload).then(({ data }) => data),

  createGallery: (payload) =>
    client.post(`/api/v1/createGallery`, payload).then(({ data }) => data),

  deleteGallery: (id) =>
    client.delete(`/api/v1/deleteGallery/${id}`).then(({ data }) => data),
};
