import React from "react";
import { Input } from "./components";
import { Box } from "theme-ui";
import useContact from "../../../hooks/contactus/useContact";

const Shop = () => {
  const { data } = useContact();

  const { shop } = data;

  return (
    <Box sx={{ padding: "5px" }}>
      <Input payload={"shop"} value={shop} />
    </Box>
  );
};

export default Shop;
