/* eslint-disable no-restricted-globals */
import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { api } from "../../api/newsletter";
import toast from "react-hot-toast";

const useNewsletter = () => {
  const [modal, showModal] = useState(false);

  const { mutateAsync: UnSubscribe } = useMutation(api.unSubscribe);
  const { mutateAsync: sendLetter } = useMutation(api.sendLetter);

  const { data, isLoading, refetch } = useQuery(
    ["News Letter"],
    () => {
      return api.getSubscribers();
    },
    {
      cacheTime: 300,
      staleTime: 300,
      select: (data) => data?.message,
    }
  );

  const handleModal = () => {
    showModal(!modal);
  };

  const handleUnsubscribe = (email) => {
    return async () => {
      if (confirm("Are you sure you want to remove this subscriber?")) {
        toast.loading("Processing");
        try {
          const data = await UnSubscribe(email);
          toast.remove();
          toast.success(data);
          setTimeout(() => {
            refetch();
          }, 1000);
        } catch ({ response }) {
          console.log(response);
          toast.remove();
          toast.error(response?.data?.message);
        }
      }
    };
  };

  const handleNewsletter = async (payload) => {
    toast.loading("Processing");
    try {
      const data = await sendLetter(payload);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const pageProps = { isLoading, title: "News-Letter" };
  const modalProps = {
    title: "News Letter",
    open: modal,
    setOpen: handleModal,
  };

  return {
    isLoading,
    refetch,
    modal,
    handleModal,
    handleUnsubscribe,
    handleNewsletter,
    data,
    pageProps,
    modalProps,
  };
};

export default useNewsletter;
