import React from "react";
import { Box, Input, Textarea, Button } from "theme-ui";
import { useForm } from "react-hook-form";
import { input_ } from "../Signin.styles";
import useNewsletter from "../../hooks/newsletter/useNewsletter";

const News = () => {
  const { register, handleSubmit } = useForm();

  const { handleNewsletter } = useNewsletter();

  return (
    <Box>
      <form onSubmit={handleSubmit(handleNewsletter)}>
        <Input
          type="text"
          {...register("emailSubject", {
            required: true,
          })}
          sx={input_}
          className="duration-200"
          placeholder="Subject *"
        />
        <Textarea
          sx={input_}
          {...register("emailBody", {
            required: true,
          })}
          className="duration-200"
          placeholder="Body *"
          rows={"5"}
        />
        <Button sx={{ width: "100%" }} type="submit">
          Send Newsletter
        </Button>
      </form>
    </Box>
  );
};

export default News;
