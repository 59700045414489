import React from "react";
import { Box, Text, Flex, Button } from "theme-ui";
import useReview from "../../hooks/review/useReview";
import { container, review_style, button } from "./style";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import ViewImage from "./ViewImage";
import CenteredModal from "../../components/modal/Modal";
import EditReview from "./EditReview";

const ReviewList = () => {
  const { data, modal, editModal, handleModal, handleEditModal, removeReview } =
    useReview();

  const handleView = (member) => {
    return () => {
      toast.loading("Processing");
      sessionStorage.setItem("review_url", member?.url);
      sessionStorage.setItem("review_picture", member?.picture);
      setTimeout(() => {
        handleModal();
        toast.remove();
      }, 1000);
    };
  };

  const handleEdit = (member) => {
    return () => {
      toast.loading("Processing...");
      sessionStorage.setItem("current_review", JSON.stringify(member));
      setTimeout(() => {
        toast.remove();
        handleEditModal();
      }, 1000);
    };
  };

  const handleRemove = (member) => {
    return () => {
      toast.loading("Processing...");
      sessionStorage.setItem("current_review", JSON.stringify(member));
      setTimeout(() => {
        toast.remove();
        removeReview();
      }, 1000);
    };
  };

  return (
    <Box sx={{ marginTop: "10px", padding: "10px" }}>
      {data &&
        data?.map((review, index) => (
          <Flex sx={container} key={index}>
            <Text>First Name: {review?.fName}</Text>
            <Text>Last Name: {review?.lName}</Text>
            <Text sx={review_style}>Review: {review?.review}</Text>
            <Flex sx={{ position: "absolute", top: "0", right: "5px" }}>
              <Button sx={button} onClick={handleView(review)}>
                <FontAwesomeIcon icon={faEye} className="text-slate-500" />
              </Button>
              <Button sx={button} onClick={handleEdit(review)}>
                <FontAwesomeIcon icon={faEdit} className="text-sub_primary" />
              </Button>
              <Button sx={button} onClick={handleRemove(review)}>
                <FontAwesomeIcon icon={faTrash} className="text-secondary" />
              </Button>
            </Flex>
          </Flex>
        ))}
      <CenteredModal open={modal} setOpen={handleModal}>
        <ViewImage />
      </CenteredModal>
      <CenteredModal open={editModal} setOpen={handleEditModal}>
        <EditReview />
      </CenteredModal>
    </Box>
  );
};

export default ReviewList;
