/* eslint-disable no-restricted-globals */
import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { api } from "../../api/partners";
import toast from "react-hot-toast";
import ImageContainer from "../../pages/partners/ImageContainer";
import { config } from "../../config";

const usePartners = () => {
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { mutateAsync: CreatePartner } = useMutation(api.createPartner);
  const { mutateAsync: DeletePartner } = useMutation(api.deletePartner);
  const { mutateAsync: UpdatePartner } = useMutation(api.updatePartner);

  const {
    data: partners,
    isLoading,
    refetch,
  } = useQuery(
    ["Partners"],
    () => {
      return api.getPartners();
    },
    {
      cacheTime: 300,
      staleTime: 300,
      select: (data) => data?.message,
    }
  );

  const [picture, setPicture] = useState(null);

  const handleAddModal = () => {
    setAddModal(!addModal);
  };

  const handleEditModal = () => {
    setEditModal(!editModal);
  };

  const addNewPartner = async (values) => {
    if (!picture) return toast.error("Please upload a picture");

    toast.loading("Adding new partner...");

    try {
      const payload = new FormData();
      payload.append("companyName", values?.companyName);
      payload.append("picture", picture);

      const data = await CreatePartner(payload);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const removePartner = async () => {
    const current_partner = JSON.parse(
      sessionStorage.getItem("current_partner")
    );
    const { companyName, _id } = current_partner;

    if (confirm(`Are you sure you want to remove ${companyName}`)) {
      toast.loading("Removing partner...");

      try {
        const data = await DeletePartner(_id);
        toast.remove();
        toast.success(data?.message);
        setTimeout(() => {
          refetch();
        }, 1000);
      } catch ({ response }) {
        toast.remove();
        toast.error(response?.data?.message);
      }
    }
  };

  const updatePartner = async (values) => {
    const current_partner = JSON.parse(
      sessionStorage.getItem("current_partner")
    );
    const { _id } = current_partner;

    toast.loading("Updating partner...");

    try {
      const payload = new FormData();
      payload.append("companyName", values?.companyName);
      picture && payload.append("picture", picture);

      const data = await UpdatePartner({ _id, payload });
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const data = {
    rows: partners?.map((partner) => {
      return {
        title: partner?.companyName,
        content: (
          <ImageContainer
            src={`${baseUrl}/${partner?.url}${partner?.companyLogo}`}
            alt={partner?.companyName}
            partner={partner}
          />
        ),
      };
    }),
  };

  const faqProps = { data, config };

  const pageProps = { isLoading, title: "Partners" };

  return {
    data,
    isLoading,
    addModal,
    editModal,
    handleAddModal,
    handleEditModal,
    picture,
    setPicture,
    addNewPartner,
    removePartner,
    updatePartner,
    baseUrl,
    faqProps,
    pageProps,
  };
};

export default usePartners;
