import ApiClient from "./api";

const client = ApiClient();

export const api = {
  getPartners: () => client.get(`/api/v1/getPartner`).then(({ data }) => data),

  createPartner: (payload) =>
    client.post(`/api/v1/createPartner`, payload).then(({ data }) => data),

  updatePartner: ({ _id, payload }) =>
    client
      .patch(`/api/v1/editPartner/${_id}`, payload)
      .then(({ data }) => data),

  deletePartner: (_id) =>
    client.delete(`/api/v1/deletePartner/${_id}`).then(({ data }) => data),
};
