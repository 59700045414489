import ApiClient from "./api";

const client = ApiClient();

export const api = {
  getUsers: () =>
    client
      .get(`/api/v1/getStaff?sort=fName&limit=10&page=1`)
      .then(({ data }) => data),

  addUser: (payload) =>
    client.post(`/api/v1/signUp`, payload).then(({ data }) => data),

  editUser: ({ _id, payload }) =>
    client.patch(`/api/v1/editStaff/${_id}`, payload).then(({ data }) => data),

  deactivateUser: ({ active, _id }) =>
    client.delete(`/api/v1/deactivateStaff/${_id}?active=${active}`),

  getRoles: () => client.get(`/api/v1/getRoles`).then(({ data }) => data),
};
