import React from "react";
import PageLayout from "../../components/layout/PageLayout";
import useReview from "../../hooks/review/useReview";
import ReviewList from "./ReviewList";
import { Button } from "theme-ui";
import CenteredModal from "../../components/modal/Modal";
import CreateReview from "./CreateReview";

const Review = () => {
  const { isLoading, addModal, handleAddModal } = useReview();
  const pageProps = { isLoading, title: "Review" };

  return (
    <PageLayout {...pageProps}>
      <Button onClick={handleAddModal}>Add Review</Button>
      <ReviewList />
      <CenteredModal open={addModal} setOpen={handleAddModal}>
        <CreateReview />
      </CenteredModal>
    </PageLayout>
  );
};

export default Review;
