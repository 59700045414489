import ApiClient from "./api";

const client = ApiClient();

export const api = {
  getAboutUs: () => client.get(`/api/v1/getAboutUs`).then(({ data }) => data),

  editAboutUs: (payload) =>
    client.patch(`/api/v1/editAboutUs`, payload).then(({ data }) => data),
};
