import React from "react";
import { Image, Flex } from "theme-ui";

const ViewImage = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const url = sessionStorage.getItem("team_member_url");
  const picture = sessionStorage.getItem("team_member_picture");

  return (
    <Flex
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Image
        src={`${baseUrl}/${url}/${picture}`}
        alt=""
        className="max-w-[90%] max-h-[300px] md:max-w-[400px] md:max-h-[200px] mt-4"
      />
    </Flex>
  );
};

export default ViewImage;
