import "./App.css";
import { useEffect } from "react";
import { ThemeProvider } from "@theme-ui/core";
import { theme } from "./theme";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import Signin from "./pages/Signin";
import DashboardRoutes from "./pages/dashboard/DashboardRoutes";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import toast, { Toaster } from "react-hot-toast";
import { toastOptions } from "./toast";

const queryClient = new QueryClient();

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = location.pathname;

  useEffect(() => {
    const user_data = sessionStorage.getItem("user_data");
    if (!user_data && currentRoute.includes("/dashboard")) {
      navigate("/");
      toast.remove();
      toast("We have signed you out!");
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <Routes>
          <Route path="/" exact element={<Signin />} />
          <Route path="/dashboard/*" exact element={<DashboardRoutes />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </ThemeProvider>
      <Toaster toastOptions={toastOptions} />
    </QueryClientProvider>
  );
}

export default App;
