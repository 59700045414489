import React from "react";
import { Input } from "./components";
import { Box } from "theme-ui";
import useContact from "../../../hooks/contactus/useContact";

const Email = () => {
  const { data } = useContact();

  const { email } = data;

  return (
    <Box sx={{ padding: "5px" }}>
      <Input payload={"email"} value={email} />
    </Box>
  );
};

export default Email;
