export const container = {
  backgroundColor: "#EDF2F7",
  padding: "20px",
  marginBottom: "20px",
  borderRadius: "5px",
  flexDirection: "column",
  gap: "10px",
  overflowY: "scroll",
  height: "210px",
  position: "relative",
};

export const about = {
  borderRadius: "5px",
  padding: "3px",
  backgroundColor: "#fff",
};

export const button = {
  backgroundColor: "transparent",
  width: "10px",
};
