import React from "react";
import Table from "../../components/table/Table";
import { Box } from "theme-ui";
import useUsers from "../../hooks/users/useUsers";
import useColumns from "../../hooks/users/useColumns";
import useWindowDimensions from "../../hooks/useWindowSize";
import CenteredModal from "../../components/modal/Modal";
import EditUser from "./EditUser";

const UsersList = () => {
  const { data } = useUsers();
  const { columns, editModal, handleEditModal } = useColumns();

  // console.log(editModal, "list");

  const { isDesktop } = useWindowDimensions();

  const tableProps = { columns, data };

  const list_style = {
    overflowX: "scroll",
    border: !isDesktop && "1px solid #ccc",
    width: "100%",
    marginBottom: "10px",
    padding: "10px",
  };

  return (
    <Box sx={list_style}>
      <Table {...tableProps} />
      <CenteredModal title={"."} open={editModal} setOpen={handleEditModal}>
        <EditUser handleModal={handleEditModal} />
      </CenteredModal>
    </Box>
  );
};

export default UsersList;
