import ApiClient from "./api";

const client = ApiClient();

export const api = {
  getSubscribers: () =>
    client.get(`/api/v1/getSubscribers`).then(({ data }) => data),

  unSubscribe: (email) =>
    client.get(`/api/v1/unSubscribe/${email}`).then(({ data }) => data),

  sendLetter: (payload) =>
    client.post(`/api/v1/sendNewsLetter`, payload).then(({ data }) => data),
};
