import React from "react";
import { Box, Input, Button } from "theme-ui";
import { input_ } from "../Signin.styles";
import { useForm } from "react-hook-form";
import usePartners from "../../hooks/partners/usePartners";

const EditPartner = () => {
  const { register, handleSubmit } = useForm();

  const { updatePartner, setPicture, picture } = usePartners();

  const current_partner = JSON.parse(sessionStorage.getItem("current_partner"));

  return (
    <Box>
      <form onSubmit={handleSubmit(updatePartner)}>
        <Input
          type="text"
          {...register("companyName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          defaultValue={current_partner?.companyName}
          placeholder="Company Name *"
        />
        <Input
          type="file"
          onChange={(e) => setPicture(e.target.files[0])}
          sx={{ display: "none" }}
        />
        <Box sx={input_}>
          <label className="cursor-pointer" htmlFor="file_input">
            {picture ? picture?.name : current_partner?.companyLogo}
          </label>
        </Box>
        <Button
          sx={{
            backgroundColor: "primary",
            padding: "15px 0",
            width: "100%",
          }}
          type="submit"
        >
          Edit Partner
        </Button>
      </form>
    </Box>
  );
};

export default EditPartner;
