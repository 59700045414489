/* eslint-disable no-restricted-globals */
import { useQuery, useMutation } from "react-query";
import { api } from "../../api/aboutus";
import About from "../../pages/aboutus/components/About";
import Journey from "../../pages/aboutus/components/Journey";
import Mission from "../../pages/aboutus/components/Mission";
import Vision from "../../pages/aboutus/components/Vision";
import WhoWeAre from "../../pages/aboutus/components/WhoWeAre";
import YoutubeLink from "../../pages/aboutus/components/YoutubeLink";
import toast from "react-hot-toast";

const useAbout = () => {
  const pages = {
    rows: [
      {
        title: "About Us",
        content: <About />,
      },
      {
        title: "Journey",
        content: <Journey />,
      },
      {
        title: "Mission",
        content: <Mission />,
      },
      {
        title: "Vision",
        content: <Vision />,
      },
      {
        title: "Who We Are",
        content: <WhoWeAre />,
      },
      {
        title: "Youtube Link",
        content: <YoutubeLink />,
      },
    ],
  };

  const { mutateAsync: EditAboutUs } = useMutation(api.editAboutUs);

  const { data, isLoading, refetch } = useQuery(
    ["About Us"],
    () => {
      return api.getAboutUs();
    },
    {
      cacheTime: 300,
      staleTime: 300,
      select: (data) => data?.message,
    }
  );

  const updateAboutUs = async (values) => {
    // console.log(values);
    toast.loading("Updating...");

    try {
      const data = await EditAboutUs(values);
      //   console.log(data);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        location.reload();
      }, 1500);
    } catch ({ response }) {
      console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return {
    isLoading,
    refetch,
    data,
    pages,
    updateAboutUs,
  };
};

export default useAbout;
