import React from "react";
import { Location as LocationInput } from "./components";
import { Box } from "theme-ui";
import useContact from "../../../hooks/contactus/useContact";

const Location = () => {
  const { data } = useContact();

  const { officeLocation } = data;

  return (
    <Box sx={{ padding: "5px" }}>
      <LocationInput value={officeLocation} />
    </Box>
  );
};

export default Location;
