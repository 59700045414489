import React, { useRef } from "react";
import { Box, Flex, Button } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const ImageModal = ({ handleModal }) => {
  const backdropRef = useRef(null);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const url = sessionStorage.getItem("current_url");
  const picture = sessionStorage.getItem("current_picture");

  const handleBackdropClick = (event) => {
    // Check if the backdrop element was clicked (not its children)
    if (event.target === backdropRef.current) {
      handleModal();
    }
  };

  return (
    <Box
      className="fixed inset-0 z-500 overflow-auto bg-slate-800 bg-opacity-50 flex justify-center items-center"
      ref={backdropRef}
      onClick={handleBackdropClick}
    >
      <Flex className="bg-transparent rounded-md w-full max-w-[95%] md:max-w-[500px] mx-auto p-7 bg-white items-center justify-center flex-col">
        <Flex className="items-center justify-end w-full">
          <Button sx={{ backgroundColor: "transparent" }} onClick={handleModal}>
            <FontAwesomeIcon className="text-slate-500" icon={faXmark} />
          </Button>
        </Flex>
        <img
          src={`${baseUrl}/${url}/${picture}`}
          alt=""
          className="max-w-[90%] max-h-[300px] md:max-w-[400px] md:max-h-[200px] mt-4"
        />
      </Flex>
    </Box>
  );
};

export default ImageModal;
