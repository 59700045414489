import React from "react";
import PageLayout from "../../components/layout/PageLayout";
import useProducts from "../../hooks/products/useProducts";
import ProductList from "./ProductList";
import { Button } from "theme-ui";
import CenteredModal from "../../components/modal/Modal";
import AddProduct from "./AddProduct";

const Products = () => {
  const { pageProps, handleAddModal, addModal } = useProducts();

  return (
    <PageLayout {...pageProps}>
      <Button onClick={handleAddModal}>Add Product</Button>
      <ProductList />
      <CenteredModal open={addModal} setOpen={handleAddModal}>
        <AddProduct />
      </CenteredModal>
    </PageLayout>
  );
};

export default Products;
