import React from "react";
import { Box, Input, Button } from "theme-ui";
import { input_ } from "../Signin.styles";
import { useForm } from "react-hook-form";
import usePartners from "../../hooks/partners/usePartners";

const AddPartner = () => {
  const { register, handleSubmit } = useForm();

  const { addNewPartner, setPicture } = usePartners();

  return (
    <Box>
      <form onSubmit={handleSubmit(addNewPartner)}>
        <Input
          type="text"
          {...register("companyName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="Company Name *"
        />
        <Input
          type="file"
          onChange={(e) => setPicture(e.target.files[0])}
          sx={input_}
          className="duration-200 focus:bg-white"
        />
        <Button
          sx={{
            backgroundColor: "primary",
            padding: "15px 0",
            width: "100%",
          }}
          type="submit"
        >
          Add Partner
        </Button>
      </form>
    </Box>
  );
};

export default AddPartner;
