export const sidebarStyle_ = {
  flex: "0.9",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#fff",
  padding: "15px 0",
};

export const linkStyle_ = {
  width: "90%",
  margin: "10px auto",
  padding: "5px 10px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: "10px",
};

//styling when the link is active
export const activePath_ = "my-5 text-link_color";

//Styling when the link is dormant
export const dormantPath_ = "my-5 text-black";
