import { Textarea, Button, Input as FormInput, Flex } from "theme-ui";
import { useForm } from "react-hook-form";
import useContact from "../../../hooks/contactus/useContact";
import { useState } from "react";

export const Input = ({ value, payload }) => {
  const { register, handleSubmit } = useForm();

  const { updateContactUs } = useContact();

  return (
    <form onSubmit={handleSubmit(updateContactUs)}>
      <Textarea
        sx={{
          border: "1px solid #ccc",
          backgroundColor: "#fff",
          paddingY: "12px",
          marginBottom: "10px",
          outline: "none",

          "&:focus": {
            border: "1px solid #ca951e",
          },
        }}
        {...register(payload, {
          required: true,
        })}
        className="duration-200"
        placeholder="Say something..."
        defaultValue={value}
        rows={"5"}
      />
      <Button type="submit">Update</Button>
    </form>
  );
};

export const Location = ({ value }) => {
  const [officeLocation, setOfficeLocation] = useState({
    address: value?.address,
    coordinate: value?.coordinate,
  });

  const { updateContactUs } = useContact();

  const handleCoordinateChange = (index, newValue) => {
    setOfficeLocation((prev) => {
      const newCoordinate = [...prev.coordinate];
      newCoordinate[index] = newValue;
      return { ...prev, coordinate: newCoordinate };
    });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        updateContactUs({ officeLocation });
      }}
    >
      <Flex sx={{ gap: "10px" }}>
        <FormInput
          type="text"
          sx={{
            border: "1px solid #ccc",
            backgroundColor: "#fff",
            paddingY: "12px",
            marginBottom: "10px",
            outline: "none",

            "&:focus": {
              border: "1px solid #ca951e",
            },
          }}
          className="duration-200"
          placeholder="Say something..."
          defaultValue={value?.coordinate[0]}
          onChange={(e) =>
            handleCoordinateChange(0, parseFloat(e.target.value))
          }
        />
        <FormInput
          type="text"
          sx={{
            border: "1px solid #ccc",
            backgroundColor: "#fff",
            paddingY: "12px",
            marginBottom: "10px",
            outline: "none",

            "&:focus": {
              border: "1px solid #ca951e",
            },
          }}
          className="duration-200"
          placeholder="Say something..."
          defaultValue={value?.coordinate[1]}
          onChange={(e) =>
            handleCoordinateChange(1, parseFloat(e.target.value))
          }
        />
      </Flex>
      <Textarea
        sx={{
          border: "1px solid #ccc",
          backgroundColor: "#fff",
          paddingY: "12px",
          marginBottom: "10px",
          outline: "none",

          "&:focus": {
            border: "1px solid #ca951e",
          },
        }}
        className="duration-200"
        placeholder="Say something..."
        defaultValue={value?.address}
        rows={"5"}
        onChange={(e) => {
          setOfficeLocation((prev) => {
            return { ...prev, address: e.target.value };
          });
        }}
      />
      <Button type="submit">Update</Button>
    </form>
  );
};
