import React from "react";
import { Flex, Box, Text, Button } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import useTeam from "../../hooks/team/useTeam";
import CenteredModal from "../../components/modal/Modal";
import ViewImage from "./ViewImage";
import toast from "react-hot-toast";
import EditMember from "./EditMember";
import { container, about, button } from "./style";

const TeamList = () => {
  const {
    data,
    modal,
    handleModal,
    removeTeamMember,
    handleEditModal,
    editModal,
  } = useTeam();

  const handleView = (member) => {
    return () => {
      toast.loading("Processing");
      sessionStorage.setItem("team_member_url", member?.url);
      sessionStorage.setItem("team_member_picture", member?.picture);
      setTimeout(() => {
        handleModal();
        toast.remove();
      }, 1000);
    };
  };

  const handleEdit = (member) => {
    return () => {
      toast.loading("Processing...");
      sessionStorage.setItem("current_team_member", JSON.stringify(member));
      setTimeout(() => {
        toast.remove();
        handleEditModal();
      }, 1000);
    };
  };

  const handleRemove = (member) => {
    return () => {
      toast.loading("Processing...");
      sessionStorage.setItem("current_team_member", JSON.stringify(member));
      setTimeout(() => {
        toast.remove();
        removeTeamMember();
      }, 1000);
    };
  };

  return (
    <Box padding="10px" marginTop="10px">
      {data &&
        data?.map((member, index) => (
          <Flex sx={container} key={index}>
            <Text>First Name: {member?.fName}</Text>
            <Text>Last Name: {member?.lName}</Text>
            <Text>E-mail: {member?.email}</Text>
            <Text>Role: {member?.role}</Text>
            <Text sx={about}>About: {member?.about}</Text>
            <Flex sx={{ position: "absolute", top: "0", right: "0" }}>
              <Button sx={button} onClick={handleView(member)}>
                <FontAwesomeIcon icon={faEye} className="text-slate-500" />
              </Button>
              <Button sx={button} onClick={handleEdit(member)}>
                <FontAwesomeIcon icon={faEdit} className="text-sub_primary" />
              </Button>
              <Button sx={button} onClick={handleRemove(member)}>
                <FontAwesomeIcon icon={faTrash} className="text-secondary" />
              </Button>
            </Flex>
          </Flex>
        ))}
      <CenteredModal open={modal} setOpen={handleModal}>
        <ViewImage />
      </CenteredModal>
      <CenteredModal open={editModal} setOpen={handleEditModal}>
        <EditMember />
      </CenteredModal>
    </Box>
  );
};

export default TeamList;
