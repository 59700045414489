import React from "react";
import { Box, Text, Flex, Button } from "theme-ui";
import useNewsletter from "../../hooks/newsletter/useNewsletter";
import { header } from "./style";

const SubscribersList = () => {
  const { data, handleUnsubscribe } = useNewsletter();

  return (
    <Box sx={{ padding: "10px" }}>
      <Text sx={header}>List of Subscribers</Text>
      {data &&
        data?.map((subscriber, index) => (
          <Flex
            key={index}
            sx={{
              padding: "10px",
              borderBottom: "1px solid #ccc",
              borderTop: index === 0 && "1px solid #ccc",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Text>{index + 1}. </Text>
              <Text>{subscriber}</Text>
            </Box>
            <Button onClick={handleUnsubscribe(subscriber)}>
              Un-subscribe
            </Button>
          </Flex>
        ))}
    </Box>
  );
};

export default SubscribersList;
