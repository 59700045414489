import React from "react";
import { Flex, Text, Button, Box } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt, faBars, faX } from "@fortawesome/free-solid-svg-icons";
import { headerStyle_, boxStyle_, buttonStyle_ } from "./style";
import { useNavigate } from "react-router-dom";

const Header = ({ width, isOpen, setIsOpen }) => {
  const user_data = JSON.parse(sessionStorage.getItem("user_data"));
  const navigate = useNavigate();

  const routeToLogin = () => {
    navigate("/");
    sessionStorage.clear();
  };

  return (
    <Flex
      sx={headerStyle_}
      className={width < 768 ? "justify-between" : "justify-end"}
    >
      {width < 768 && (
        <Button
          sx={{ backgroundColor: "#fff", color: "#000" }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {isOpen ? (
            <FontAwesomeIcon icon={faX} />
          ) : (
            <FontAwesomeIcon icon={faBars} />
          )}
        </Button>
      )}
      <Box sx={boxStyle_}>
        <FontAwesomeIcon icon={faUserAlt} />
        <Text>{user_data?.fName}</Text>
        <Button sx={buttonStyle_} onClick={routeToLogin}>
          Logout
        </Button>
      </Box>
    </Flex>
  );
};

export default Header;
