import React from "react";
import { Box, Image } from "theme-ui";
import { routes } from "../../routes";
import { Link, useLocation } from "react-router-dom";
import Logo from "../logo/Logo";
import logo from "../../assets/logo.png";
import { sidebarStyle_, linkStyle_, activePath_, dormantPath_ } from "./style";

const Sidebar = () => {
  const location = useLocation();

  return (
    <Box sx={sidebarStyle_}>
      {/* <Logo /> */}
      <Image src={logo} width={100} height={50} sx={{ marginBottom: "25px" }} />
      {routes.map((route) => (
        <Link
          key={route.id}
          style={linkStyle_}
          className={
            location.pathname === route.path ? activePath_ : dormantPath_
          }
          to={route.path}
        >
          {route.icon} <p>{route.title}</p>
        </Link>
      ))}
    </Box>
  );
};

export default Sidebar;
