import React from "react";
import { Input } from "./components";
import { Box } from "theme-ui";
import useContact from "../../../hooks/contactus/useContact";

const WhatsApp = () => {
  const { data } = useContact();

  const { whatsApp } = data;

  return (
    <Box sx={{ padding: "5px" }}>
      <Input payload={"whatsApp"} value={whatsApp} />
    </Box>
  );
};

export default WhatsApp;
