import React from "react";
import PageLayout from "../../components/layout/PageLayout";
import Faq from "react-faq-component";
import { Box } from "theme-ui";
import useAbout from "../../hooks/aboutus/useAbout";
import { config } from "../../config";

const AboutUs = () => {
  const { isLoading, pages: data } = useAbout();

  const layoutProps = { isLoading, title: "About Us" };
  const faqProps = { data, config };

  return (
    <PageLayout {...layoutProps}>
      <Box padding={"10px"}>
        <Faq {...faqProps} />
      </Box>
    </PageLayout>
  );
};

export default AboutUs;
