import React from "react";
import PageLayout from "../../components/layout/PageLayout";
import PartnersList from "./PartnersList";
import { Button } from "theme-ui";
import usePartners from "../../hooks/partners/usePartners";
import CenteredModal from "../../components/modal/Modal";
import AddPartner from "./AddPartner";

const Partners = () => {
  const { pageProps, addModal, handleAddModal } = usePartners();

  return (
    <PageLayout {...pageProps}>
      <Button onClick={handleAddModal}>Add Partner</Button>
      <PartnersList />
      <CenteredModal open={addModal} setOpen={handleAddModal}>
        <AddPartner />
      </CenteredModal>
    </PageLayout>
  );
};

export default Partners;
