import React from "react";
import { Input } from "./components";
import { Box } from "theme-ui";
import useContact from "../../../hooks/contactus/useContact";

const WhatsAppLink = () => {
  const { data } = useContact();

  const { whatsAppLink } = data;

  return (
    <Box sx={{ padding: "5px" }}>
      <Input payload={"whatsAppLink"} value={whatsAppLink} />
    </Box>
  );
};

export default WhatsAppLink;
