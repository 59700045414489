/* eslint-disable no-restricted-globals */
import React, { useMemo, useState } from "react";
import { Box, Text } from "theme-ui";
import VerticalMenu from "../../components/menu/Menu";
import useDeactivateUser from "./useDeactivateUser";
import { api } from "../../api/users";
import { useMutation } from "react-query";
import toast from "react-hot-toast";

const Columns = () => {
  const { deactivateUser } = useDeactivateUser();

  const [editModal, setEditModal] = useState(false);

  const { mutateAsync: EditUser } = useMutation(api.editUser);

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  // console.log(editModal, "c");

  const current_user_edit = JSON.parse(
    sessionStorage.getItem("current_user_edit")
  );

  const handleEditModal = () => {
    setEditModal(!editModal);
  };

  const handlePermissions = (role) => {
    const isSelected = selectedPermissions.includes(role.permission);

    if (isSelected) {
      setSelectedPermissions((prevPermissions) =>
        prevPermissions.filter((p) => p !== role.permission)
      );
    } else {
      setSelectedPermissions((prevPermissions) => [
        ...prevPermissions,
        role.permission,
      ]);
    }
  };

  const handleEdit = async (values) => {
    // console.log(values);
    toast.loading("Editing account...");

    const payload = { ...values, permissions: selectedPermissions };

    const { _id } = current_user_edit;

    try {
      await EditUser({ _id, payload });
      //   console.log(data);
      toast.remove();
      toast.success("Account edited successfully...");
      setTimeout(() => {
        location.reload();
      }, 1000);
    } catch ({ response }) {
      console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  let rowOptions = [
    {
      name: "Activate/De-activate User?",
      action: (row) => {
        // console.log(row);
        deactivateUser(row);
      },
    },
    {
      name: "Edit User",
      action: (row) => {
        sessionStorage.setItem("current_user_edit", JSON.stringify(row));
        handleEditModal();
      },
    },
  ];

  const columns = useMemo(
    () => [
      {
        title: "S/N",
        selector: null,
        key: "id",
        render: ({ index }) => {
          return <Text>{index + 1}</Text>;
        },
      },
      {
        title: "User",
        selector: null,
        key: "title",
        render: ({ row }) => {
          const { fName, lName } = row;
          return (
            <Text>
              {fName} {lName}
            </Text>
          );
        },
      },
      {
        title: "E-mail",
        selector: "email",
        key: "email",
      },
      {
        title: "Status",
        selector: "active",
        key: "active",
        render: ({ item }) => {
          if (item)
            return (
              <Box
                sx={{
                  border: "1px dotted #0e542e",
                  color: "#0e542e",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  width: "80px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Active
              </Box>
            );
          return (
            <Box
              sx={{
                border: "1px dotted #ca951e",
                color: "#ca951e",
                padding: "5px 10px",
                borderRadius: "5px",
                width: "80px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              In-active
            </Box>
          );
        },
      },
      {
        title: "Permissions",
        selector: "permissions",
        key: "permissions",
        render: ({ item }) => {
          return <Text>{item?.join(", ")}</Text>;
        },
      },
      {
        title: "Actions",
        selector: null,
        key: "actions",
        render: ({ row }) => {
          return (
            <VerticalMenu isBackground>
              <Box>
                {rowOptions?.map((option, index) => (
                  <button
                    key={index}
                    onClick={() => {
                      option?.action(row);
                    }}
                    className="block w-full border border-slate-100 px-4 py-2 text-left bg-white duration-200 text-baseFont text-slate-700 hover:bg-slate-100 hover:text-slate-900 cursor-pointer"
                  >
                    {option?.name}
                  </button>
                ))}
              </Box>
            </VerticalMenu>
          );
        },
      },
    ],
    []
  );
  return {
    editModal,
    columns,
    handleEditModal,
    setEditModal,
    handleEdit,
    handlePermissions,
    selectedPermissions,
    setSelectedPermissions,
  };
};

export default Columns;
