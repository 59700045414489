import React from "react";
import { Box, Input, Button, Textarea } from "theme-ui";
import { input_ } from "../Signin.styles";
import { useForm } from "react-hook-form";
import useReview from "../../hooks/review/useReview";

const EditReview = () => {
  const { register, handleSubmit } = useForm();

  const { updateReview, setPicture, picture } = useReview();

  const current_review = JSON.parse(sessionStorage.getItem("current_review"));

  return (
    <Box>
      <form onSubmit={handleSubmit(updateReview)}>
        <Input
          type="text"
          {...register("fName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          defaultValue={current_review?.fName}
          placeholder="First Name *"
        />
        <Input
          type="text"
          {...register("lName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          defaultValue={current_review?.lName}
          placeholder="Last Name *"
        />
        <Textarea
          sx={input_}
          {...register("review", {
            required: true,
          })}
          className="duration-200"
          defaultValue={current_review?.review}
          placeholder="About *"
          rows={"5"}
        />
        <Box sx={input_}>
          <label className="cursor-pointer" htmlFor="file_input">
            {picture ? picture?.name : current_review?.picture}
          </label>
        </Box>
        <Input
          type="file"
          id="file_input"
          onChange={(e) => setPicture(e.target.files[0])}
          sx={{ display: "none" }}
        />
        <Button
          sx={{
            backgroundColor: "primary",
            padding: "15px 0",
            width: "100%",
          }}
          type="submit"
        >
          Edit Member
        </Button>
      </form>
    </Box>
  );
};

export default EditReview;
