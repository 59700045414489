import ApiClient from "./api";

const client = ApiClient();

export const api = {
  login: (payload) =>
    client.post(`/api/v1/login`, payload).then(({ data }) => data),

  forgotPassword: (payload) =>
    client.post(`/api/v1/forgotPassword`, payload).then(({ data }) => data),

  resetPassword: (payload) =>
    client.post(`/api/v1/resetPassword`, payload).then(({ data }) => data),
};
