import React from "react";
import { Box } from "theme-ui";
import useProducts from "../../hooks/products/useProducts";
import Faq from "react-faq-component";
import { products } from "./style";

const ProductList = () => {
  const { faqProps } = useProducts();

  return (
    <Box sx={products}>
      <Faq {...faqProps} />
    </Box>
  );
};

export default ProductList;
