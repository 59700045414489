import React from "react";
import { AboutInput } from "./components";
import { Box } from "theme-ui";
import useAbout from "../../../hooks/aboutus/useAbout";

const Vision = () => {
  const { data } = useAbout();

  const { vision } = data;

  return (
    <Box sx={{ padding: "5px" }}>
      <AboutInput payload={"vision"} value={vision} />
    </Box>
  );
};

export default Vision;
