import React, { useEffect } from "react";
import { Flex, Input, Button, Text } from "theme-ui";
import { useForm } from "react-hook-form";
import { input_, editFlex_ } from "../Signin.styles";
import useAddUsers from "../../hooks/users/useAddUser";
import useColumns from "../../hooks/users/useColumns";

const EditStaff = () => {
  const { register, handleSubmit } = useForm();

  const { roles } = useAddUsers();

  const {
    handleEdit,
    handlePermissions,
    selectedPermissions,
    setSelectedPermissions,
  } = useColumns();

  const current_user_edit = JSON.parse(
    sessionStorage.getItem("current_user_edit")
  );

  //   console.log(current_user_edit);

  useEffect(() => {
    setSelectedPermissions(current_user_edit?.permissions);
  }, []);

  //   console.log(roles);

  //   console.log(selectedPermissions);

  return (
    <Flex sx={editFlex_}>
      <form onSubmit={handleSubmit(handleEdit)}>
        <Input
          type="text"
          {...register("fName", {
            required: true,
          })}
          sx={input_}
          defaultValue={current_user_edit?.fName}
          className="duration-200 focus:bg-white"
          placeholder="First Name *"
        />
        <Input
          type="text"
          {...register("lName", {
            required: true,
          })}
          sx={input_}
          defaultValue={current_user_edit?.lName}
          className="duration-200 focus:bg-white"
          placeholder="Last Name *"
        />
        <Input
          type="email"
          {...register("email", {
            required: true,
          })}
          sx={input_}
          defaultValue={current_user_edit?.email}
          className="duration-200 focus:bg-white"
          placeholder="E-mail *"
        />
        <Flex sx={{ width: "100%" }}>
          <Text>Add Permissions</Text>
        </Flex>
        <Flex
          sx={{
            border: "1px solid #ccc",
            marginTop: "5px",
            marginBottom: "10px",
            gap: "20px",
            flexWrap: "wrap",
            padding: "5px",
            borderRadius: "5px",
          }}
        >
          {roles?.map((role) => (
            <Flex sx={{ gap: "5px" }} key={role?._id}>
              <input
                type="checkbox"
                checked={selectedPermissions.includes(role?.permission)}
                onChange={() => handlePermissions(role)}
              />
              {role?.permission}
            </Flex>
          ))}
        </Flex>
        <Button
          sx={{
            backgroundColor: "primary",
            padding: "15px 0",
            width: "100%",
          }}
          type="submit"
        >
          Edit Account
        </Button>
      </form>
    </Flex>
  );
};

export default EditStaff;
