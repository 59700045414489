import React, { useState } from "react";
import { Box, Text, Input, Flex, Button, Image } from "theme-ui";
import { useForm } from "react-hook-form";
import { container_, flex_, input_, brandText_ } from "./Signin.styles";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import toast from "react-hot-toast";
import { api } from "../api/auth";
import { useMutation } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Signin = () => {
  const { register, handleSubmit } = useForm();

  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const { mutateAsync } = useMutation(api.login);

  const handleLogin = async (values) => {
    // console.log(values);
    toast.loading("Logging in...");

    try {
      const data = await mutateAsync(values);
      const { result, token } = data;
      // console.log(data);
      toast.remove();
      toast.success("Logged in successfully...");
      sessionStorage.setItem("user_token", token);
      sessionStorage.setItem("user_data", JSON.stringify(result));

      if (result?.newPassword) {
        return navigate("reset-password");
      }
      navigate("/dashboard");
    } catch ({ response }) {
      // console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <React.Fragment>
      <Box sx={container_}>
        <Image
          sx={{ position: "absolute", top: "20px", left: "20px" }}
          src={logo}
          width={100}
          height={50}
        />
        <Flex sx={flex_}>
          <Text sx={brandText_}>Login</Text>
          <form onSubmit={handleSubmit(handleLogin)}>
            <Input
              type="email"
              {...register("email", {
                required: true,
              })}
              sx={input_}
              className="duration-200 focus:bg-white"
              placeholder="E-mail *"
            />
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Input
                type={showPassword ? "text" : "password"}
                {...register("password", {
                  required: true,
                })}
                sx={input_}
                className="duration-200 focus:bg-white"
                placeholder="Password *"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="absolute top-[20px] right-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              />
            </Box>
            <Button
              sx={{
                backgroundColor: "secondary",
                padding: "15px 0",
                width: "100%",
              }}
              type="submit"
            >
              Sign In
            </Button>
          </form>
          <div className="flex items-center justify-center text-[#3182CE]">
            <Link to={"/forgot-password"} className="hover:underline">
              Forgot Password?
            </Link>
          </div>
        </Flex>
      </Box>
    </React.Fragment>
  );
};

export default Signin;
