import ApiClient from "./api";

const client = ApiClient();

export const api = {
  getFaq: () => client.get(`/api/v1/getFaq`).then(({ data }) => data),

  createFaq: (payload) =>
    client.post(`/api/v1/createFaq`, payload).then(({ data }) => data),

  editFaq: ({ id, payload }) =>
    client.patch(`/api/v1/editFaq/${id}`, payload).then(({ data }) => data),

  deleteFaq: (id) =>
    client.delete(`/api/v1/deleteFaq/${id}`).then(({ data }) => data),
};
