import React from "react";
import { Flex, Image, Button } from "theme-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { button, container } from "./style";
import usePartners from "../../hooks/partners/usePartners";
import CenteredModal from "../../components/modal/Modal";
import EditPartner from "./EditPartner";

const ImageContainer = ({ src, alt, partner }) => {
  const { handleEditModal, removePartner, editModal } = usePartners();

  const handleEdit = () => {
    return () => {
      toast.loading("Processing...");
      sessionStorage.setItem("current_partner", JSON.stringify(partner));
      setTimeout(() => {
        toast.remove();
        handleEditModal();
      }, 1000);
    };
  };

  const handleRemove = () => {
    return () => {
      toast.loading("Processing...");
      sessionStorage.setItem("current_partner", JSON.stringify(partner));
      setTimeout(() => {
        toast.remove();
        removePartner();
      }, 1000);
    };
  };

  return (
    <Flex sx={container}>
      <Image src={src} alt={alt} />
      <Flex>
        <Button sx={button} onClick={handleEdit()}>
          <FontAwesomeIcon icon={faEdit} className="text-sub_primary" />
        </Button>
        <Button sx={button} onClick={handleRemove()}>
          <FontAwesomeIcon icon={faTrash} className="text-secondary" />
        </Button>
      </Flex>
      <CenteredModal open={editModal} setOpen={handleEditModal}>
        <EditPartner />
      </CenteredModal>
    </Flex>
  );
};

export default ImageContainer;
