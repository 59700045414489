/* eslint-disable no-restricted-globals */
import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { api } from "../../api/review";
import toast from "react-hot-toast";

const useReview = () => {
  const [modal, setModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [picture, setPicture] = useState(null);

  const { mutateAsync: CreateReview } = useMutation(api.createReview);
  const { mutateAsync: DeleteReview } = useMutation(api.deleteReview);
  const { mutateAsync: UpdateReview } = useMutation(api.updateReview);

  const { data, isLoading } = useQuery(
    ["Review"],
    () => {
      return api.getReviews();
    },
    {
      cacheTime: 300,
      staleTime: 300,
      select: (data) => data?.message,
    }
  );

  const handleModal = () => {
    setModal(!modal);
  };

  const handleAddModal = () => {
    setAddModal(!addModal);
  };

  const handleEditModal = () => {
    setEditModal(!editModal);
  };

  const addNewReview = async (values) => {
    if (!picture) return toast.error("Please upload a picture");

    toast.loading("Adding new review...");

    console.log(values);

    try {
      const payload = new FormData();
      payload.append("fName", values?.fName);
      payload.append("lName", values?.lName);
      payload.append("review", values?.review);
      payload.append("picture", picture);

      const data = await CreateReview(payload);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const removeReview = async () => {
    const current_review = JSON.parse(sessionStorage.getItem("current_review"));
    const { fName, lName, _id } = current_review;

    if (confirm(`Are you sure you want to remove ${fName} ${lName}`)) {
      toast.loading("Removing review...");

      try {
        const data = await DeleteReview(_id);
        toast.remove();
        toast.success(data?.message);
        setTimeout(() => {
          location.reload();
        }, 1000);
      } catch ({ response }) {
        toast.remove();
        toast.error(response?.data?.message);
      }
    }
  };

  const updateReview = async (values) => {
    const current_review = JSON.parse(sessionStorage.getItem("current_review"));
    const { _id } = current_review;

    toast.loading("Updating review...");

    try {
      const payload = new FormData();
      payload.append("fName", values?.fName);
      payload.append("lName", values?.lName);
      payload.append("review", values?.review);
      picture && payload.append("picture", picture);

      const data = await UpdateReview({ _id, payload });
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } catch ({ response }) {
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return {
    data,
    isLoading,
    modal,
    handleModal,
    addModal,
    handleAddModal,
    editModal,
    handleEditModal,
    picture,
    setPicture,
    addNewReview,
    removeReview,
    updateReview,
  };
};

export default useReview;
