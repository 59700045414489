import React from "react";
import { AboutInput } from "./components";
import { Box } from "theme-ui";
import useAbout from "../../../hooks/aboutus/useAbout";

const YoutubeLink = () => {
  const { data } = useAbout();

  const { youtubeUrl } = data;

  return (
    <Box sx={{ padding: "5px" }}>
      <AboutInput payload={"youtubeUrl"} value={youtubeUrl} />
    </Box>
  );
};

export default YoutubeLink;
