/* eslint-disable no-restricted-globals */
import { useQuery, useMutation } from "react-query";
import { api } from "../../api/contactus";
import toast from "react-hot-toast";
import Email from "../../pages/contactus/components/Email";
import Facebook from "../../pages/contactus/components/Facebook";
import Instagram from "../../pages/contactus/components/Instagram";
import Linkedin from "../../pages/contactus/components/Linkedin";
import Phone from "../../pages/contactus/components/Phone";
import Shop from "../../pages/contactus/components/Shop";
import Twitter from "../../pages/contactus/components/Twitter";
import WhatsApp from "../../pages/contactus/components/Whatsapp";
import WhatsAppLink from "../../pages/contactus/components/WhatsappLink";
import Location from "../../pages/contactus/components/Location";

const useContact = () => {
  const pages = {
    rows: [
      {
        title: "E-mail",
        content: <Email />,
      },
      {
        title: "FaceBook",
        content: <Facebook />,
      },
      {
        title: "Instagram",
        content: <Instagram />,
      },
      {
        title: "Linkedin",
        content: <Linkedin />,
      },
      {
        title: "Phone",
        content: <Phone />,
      },
      {
        title: "Shop",
        content: <Shop />,
      },
      {
        title: "Twitter",
        content: <Twitter />,
      },
      {
        title: "WhatsApp",
        content: <WhatsApp />,
      },
      {
        title: "WhatsApp Link",
        content: <WhatsAppLink />,
      },
      {
        title: "Location",
        content: <Location />,
      },
    ],
  };

  const { mutateAsync: EditContactUs } = useMutation(api.editContactUs);

  const { data, isLoading, refetch } = useQuery(
    ["Contact Us"],
    () => {
      return api.getContactUs();
    },
    {
      cacheTime: 300,
      staleTime: 300,
      select: (data) => data?.message,
    }
  );

  const updateContactUs = async (values) => {
    // console.log(values);
    toast.loading("Updating...");

    try {
      const data = await EditContactUs(values);
      //   console.log(data);
      toast.remove();
      toast.success(data?.message);
      setTimeout(() => {
        location.reload();
      }, 1500);
    } catch ({ response }) {
      console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return {
    isLoading,
    refetch,
    data,
    pages,
    updateContactUs,
  };
};

export default useContact;
