import React from "react";
import PageLayout from "../../components/layout/PageLayout";
import useContact from "../../hooks/contactus/useContact";
import { Box } from "theme-ui";
import Faq from "react-faq-component";
import { config } from "../../config";

const ContactUs = () => {
  const { isLoading, pages: data } = useContact();

  const pageProps = { isLoading, title: "Contact Us" };
  const faqProps = { data, config };

  return (
    <PageLayout {...pageProps}>
      <Box padding={"10px"}>
        <Faq {...faqProps} />
      </Box>
    </PageLayout>
  );
};

export default ContactUs;
