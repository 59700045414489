import React from "react";
import PageLayout from "../../components/layout/PageLayout";
import UsersList from "./UsersList";
import useUsers from "../../hooks/users/useUsers";
import { Button } from "theme-ui";
import CenteredModal from "../../components/modal/Modal";
import AddUser from "./AddUser";
import useAddUsers from "../../hooks/users/useAddUser";

const Users = () => {
  const { isLoading } = useUsers();
  const { modal, handleModal } = useAddUsers();

  const pageProps = { isLoading, title: "Users" };

  return (
    <PageLayout {...pageProps}>
      <UsersList />
      <Button onClick={handleModal}>Add New Staff</Button>
      <CenteredModal title={"Add Staff"} open={modal} setOpen={handleModal}>
        <AddUser handleModal={handleModal} />
      </CenteredModal>
    </PageLayout>
  );
};

export default Users;
