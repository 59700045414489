import React, { useState } from "react";
import { Box, Text, Input, Flex, Button, Image } from "theme-ui";
import { useForm } from "react-hook-form";
import { container_, flex_, input_, brandText_ } from "../Signin.styles";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo.png";
import toast from "react-hot-toast";
import { api } from "../../api/auth";
import { useMutation } from "react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Signin = () => {
  const { register, handleSubmit } = useForm();

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();
  const { mutateAsync } = useMutation(api.resetPassword);

  const handleResetPassword = async (values) => {
    // console.log(values);
    toast.loading("Processing...");

    try {
      const data = await mutateAsync(values);
      toast.remove();
      const { result, token } = data;
      toast.success("Password reset successful, kindly login...");
      sessionStorage.setItem("user_token", token);
      sessionStorage.setItem("user_data", JSON.stringify(result));
      setTimeout(() => {
        navigate("/dashboard");
      }, 1000);
    } catch ({ response }) {
      // console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return (
    <React.Fragment>
      <Box sx={container_}>
        <Image
          sx={{ position: "absolute", top: "20px", left: "20px" }}
          src={logo}
          width={100}
          height={50}
        />
        <Flex sx={flex_}>
          <Text sx={brandText_}>Reset Password</Text>
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Input
                type={showOldPassword ? "text" : "password"}
                {...register("oldPassword", {
                  required: true,
                })}
                sx={input_}
                className="duration-200 focus:bg-white"
                placeholder="Old Password *"
              />
              <FontAwesomeIcon
                icon={showOldPassword ? faEyeSlash : faEye}
                className="absolute top-[20px] right-3 cursor-pointer"
                onClick={() => setShowOldPassword(!showOldPassword)}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Input
                type={showPassword ? "text" : "password"}
                {...register("password", {
                  required: true,
                })}
                sx={input_}
                className="duration-200 focus:bg-white"
                placeholder="Password *"
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye}
                className="absolute top-[20px] right-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              />
            </Box>
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Input
                type={showConfirmPassword ? "text" : "password"}
                {...register("confirmPassword", {
                  required: true,
                })}
                sx={input_}
                className="duration-200 focus:bg-white"
                placeholder="Confirm Password *"
              />
              <FontAwesomeIcon
                icon={showConfirmPassword ? faEyeSlash : faEye}
                className="absolute top-[20px] right-3 cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              />
            </Box>
            <Button
              sx={{
                backgroundColor: "secondary",
                padding: "15px 0",
                width: "100%",
              }}
              type="submit"
            >
              Proceed
            </Button>
          </form>
        </Flex>
      </Box>
    </React.Fragment>
  );
};

export default Signin;
