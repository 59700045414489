import React from "react";
import { Box, Input, Button, Textarea } from "theme-ui";
import { input_ } from "../Signin.styles";
import { useForm } from "react-hook-form";
import useTeam from "../../hooks/team/useTeam";

const AddMember = () => {
  const { register, handleSubmit } = useForm();

  const { addNewMember, setPicture } = useTeam();

  return (
    <Box>
      <form onSubmit={handleSubmit(addNewMember)}>
        <Input
          type="text"
          {...register("fName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="First Name *"
        />
        <Input
          type="text"
          {...register("lName", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="Last Name *"
        />
        <Input
          type="email"
          {...register("email", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="E-mail *"
        />
        <Textarea
          sx={input_}
          {...register("about", {
            required: true,
          })}
          className="duration-200"
          placeholder="About *"
          rows={"5"}
        />
        <Input
          type="text"
          {...register("role", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="Role *"
        />
        <Input
          type="number"
          {...register("websitePosition", {
            required: true,
          })}
          sx={input_}
          className="duration-200 focus:bg-white"
          placeholder="Website Position *"
        />
        <Input
          type="file"
          onChange={(e) => setPicture(e.target.files[0])}
          sx={input_}
          className="duration-200 focus:bg-white"
        />
        <Button
          sx={{
            backgroundColor: "primary",
            padding: "15px 0",
            width: "100%",
          }}
          type="submit"
        >
          Add Member
        </Button>
      </form>
    </Box>
  );
};

export default AddMember;
