/* eslint-disable no-restricted-globals */
import { useState, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { api } from "../../api/faq";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";

const useFaq = () => {
  const { data, isLoading, refetch } = useQuery(
    ["Faq Data"],
    () => {
      return api.getFaq();
    },
    {
      cacheTime: 300,
      staleTime: 300,
      select: (data) => data?.message,
    }
  );

  const { mutateAsync: AddFaq } = useMutation(api.createFaq);
  const { mutateAsync: EditFaq } = useMutation(api.editFaq);
  const { mutateAsync: DeleteFaq } = useMutation(api.deleteFaq);

  const [faqData, setFaqData] = useState(data ? data : []);
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState("");

  useEffect(() => {
    data && setFaqData(data);
  }, [data]);

  const handleAddFaq = () => {
    const newFaq = {
      caption: "",
      picture: "",
      // dateCreated: new Date().toISOString(),
      temp_id: uuidv4(),
    };
    setFaqData([...faqData, newFaq]);
    setNewAnswer("");
    setNewQuestion("");
    sessionStorage.removeItem("current_faq_index");
  };

  const handleQuestion = (index) => {
    return (e) => {
      sessionStorage.setItem("current_faq_index", index);
      setNewQuestion(e.target.value);
    };
  };

  const handleAnswer = (index) => {
    return (e) => {
      sessionStorage.setItem("current_faq_index", index);
      setNewAnswer(e.target.value);
    };
  };

  //add new faq

  const addFaq = async () => {
    if (!newQuestion) return toast.error("Please enter a question...");
    if (!newAnswer) return toast.error("Please enter an answer...");

    toast.loading("Creating F.A.Q...");

    const payload = { question: newQuestion, answer: newAnswer };

    try {
      const data = await AddFaq(payload);
      // console.log(data);
      toast.remove();
      toast.success(data?.message);
      sessionStorage.removeItem("current_faq_index");
      setTimeout(() => {
        refetch();
        location.reload();
      }, 1500);
    } catch ({ response }) {
      console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  //update faq

  const updateFaq = async (image) => {
    toast.loading("Updating F.A.Q...");

    const payload = { question: newQuestion, answer: newAnswer };

    try {
      const data = await EditFaq({ id: image._id, payload });
      // console.log(data);
      toast.remove();
      toast.success(data?.message);
      sessionStorage.removeItem("current_faq_index");
      setTimeout(() => {
        refetch();
        location.reload();
      }, 1500);
    } catch ({ response }) {
      console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  const removeFaq = (currentItem) => {
    return async () => {
      if (
        confirm(
          "Kindly confirm you wish to remove this item before proceeding?"
        )
      ) {
        // console.log(currentItem);
        if (currentItem && !currentItem._id) {
          return setFaqData((prev) =>
            prev.filter((data) => data.temp_id !== currentItem.temp_id)
          );
        }
        toast.loading("Deleting Item...");

        try {
          const data = await DeleteFaq(currentItem?._id);
          // console.log(data);
          toast.remove();
          toast.success(data?.message);
          sessionStorage.removeItem("current_faq_index");
          setTimeout(() => {
            refetch();
            location.reload();
          }, 1500);
        } catch ({ response }) {
          console.log(response);
          toast.remove();
          toast.error(response?.data?.message);
        }
      }
    };
  };

  const handleFaq = (item) => {
    return () => {
      if (item?._id) return updateFaq(item);

      return addFaq();
    };
  };

  return {
    isLoading,
    refetch,
    faqData,
    handleAddFaq,
    handleQuestion,
    handleAnswer,
    handleFaq,
    removeFaq,
  };
};

export default useFaq;
