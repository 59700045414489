import { useQuery } from "react-query";
import { api } from "../../api/users";

const useUsers = () => {
  const { data, isLoading, refetch } = useQuery(
    ["Users"],
    () => {
      return api.getUsers();
    },
    {
      cacheTime: 300,
      staleTime: 300,
      //   select: (data) => data?.message,
    }
  );

  return { data: data?.message, isLoading, refetch };
};

export default useUsers;
