import { useQuery } from "react-query";
import { api } from "../../api/users";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import useUsers from "./useUsers";

const useDeactivateUser = () => {
  const { mutateAsync: DeactivateUser } = useMutation(api.deactivateUser);

  const { refetch } = useUsers();

  const deactivateUser = async (row) => {
    const { _id, active } = row;

    if (
      window.confirm(
        `Kindly confirm you wish to ${
          active ? "de-activate" : "activate"
        } this user?`
      )
    ) {
      toast.loading(active ? "De-activating..." : "Activating...");

      try {
        const { data } = await DeactivateUser({
          _id,
          active: active ? "no" : "yes",
        });

        toast.remove();
        toast.success(data?.message);
        refetch();
      } catch ({ response }) {
        toast.remove();
        toast.error(response?.data?.message);
      }
    }
  };

  return { deactivateUser };
};

export default useDeactivateUser;
