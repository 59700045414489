import { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { api } from "../../api/users";
import toast from "react-hot-toast";

const useAddUsers = () => {
  const [modal, setModal] = useState(false);

  const { mutateAsync: CreateUser } = useMutation(api.addUser);

  const [selectedPermissions, setSelectedPermissions] = useState([]);

  const handleModal = () => {
    setModal(!modal);
  };

  const { data: roles } = useQuery(
    ["Roles"],
    () => {
      return api.getRoles();
    },
    {
      cacheTime: 300,
      staleTime: 300,
      select: (data) => data?.message,
    }
  );

  const handlePermissions = (role) => {
    const isSelected = selectedPermissions.includes(role.permission);

    if (isSelected) {
      setSelectedPermissions((prevPermissions) =>
        prevPermissions.filter((p) => p !== role.permission)
      );
    } else {
      setSelectedPermissions((prevPermissions) => [
        ...prevPermissions,
        role.permission,
      ]);
    }
  };

  const handleSignUp = async (values) => {
    // console.log(values);
    toast.loading("Creating account...");

    try {
      await CreateUser({
        ...values,
        permissions: selectedPermissions,
      });
      //   console.log(data);
      toast.remove();
      toast.success("Account created successfully...");
    } catch ({ response }) {
      console.log(response);
      toast.remove();
      toast.error(response?.data?.message);
    }
  };

  return {
    modal,
    handleModal,
    roles,
    handlePermissions,
    selectedPermissions,
    setSelectedPermissions,
    handleSignUp,
  };
};

export default useAddUsers;
